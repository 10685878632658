import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import MultiSelectField from "pages/_components/fields/MultiSelectField";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/usersInvite";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as utilIdentification from "util/recoveryUser";
import * as Yup from "yup";
import { useEnvironmentList } from "./hooks/userInviteHooks";

const FORM_ID = "administration.users.invite";

const isSecundaryInvitation = (jsonString) => {
    try {
        const jsonObject = JSON.parse(jsonString);

        if (jsonObject) {
            return jsonObject.isSecundary;
        }
        return false;
    } catch (error) {
        return false;
    }
};

const getInvitationUsername = (jsonString) => {
    try {
        const jsonObject = JSON.parse(jsonString);

        if (jsonObject) {
            return jsonObject.username;
        }
        return false;
    } catch (error) {
        return false;
    }
};

const UserInviteStep2 = (props) => {
    const { environmentList } = props;
    const preferredCountries = ["pa", "ve"];
    const areaCodes = { ve: ["412", "414", "416", "424", "426"] };
    const environmentListOptions = useEnvironmentList(environmentList);

    const handleBack = () => {
        const { dispatch } = props;
        dispatch(routerActions.goBack());
    };

    const handleCancel = () => {
        const { dispatch } = props;
        dispatch(routerActions.push("/administration/users"));
    };

    const permissionsConfiguration = () => {
        const { roleList } = props;
        return (
            <Box display="flex" column background="component-background" borderRadius="default" className="py-6 px-9">
                <Box display="flex" className="mb-5">
                    <Text
                        component="h2"
                        className="m-0"
                        size="5"
                        color="primary"
                        bold
                        labelKey="administration.users.invite.initial.configuration.subtitle"
                    />
                </Box>
                <div className="form-group form-group--select">
                    <div className="form-group-text">
                        <label className="control-label">
                            <I18n id="administration.users.invite.transaction.permissions" />
                        </label>
                    </div>
                    <Field
                        component={Selector}
                        options={roleList}
                        idForm={FORM_ID}
                        name="role"
                        renderAs="radio"
                        column
                        gap="3"
                    />
                </div>
            </Box>
        );
    };

    const inviteToCompany = () => (
        <Box display="flex" column background="component-background" borderRadius="default" fullHeight>
            <Box
                display="flex"
                column
                className={classNames("py-6 px-9", {
                    "min-height-10rem": environmentListOptions.length === 0,
                    "min-height-15rem": environmentListOptions.length > 0,
                })}>
                <Box display="flex" className="mb-5">
                    <Text
                        component="h2"
                        className="m-0"
                        size="5"
                        color="primary"
                        bold
                        labelKey="administration.users.invite.company"
                    />
                </Box>
                {environmentListOptions.length === 0 && (
                    <Field
                        component={MultiSelectField}
                        idForm={FORM_ID}
                        name="environments"
                        options={[]}
                        labelNoMarginTop
                        disabled
                        withSelectedLabel="administration.users.invite.groups.placeholder"
                        withSelectedLabelNoMoreOptions="administration.users.invite.groups.placeholder2"
                    />
                )}

                {environmentListOptions.length === 1 && (
                    <Box
                        display="flex"
                        background="background-secondary"
                        className="py-4 px-5 my-auto"
                        borderRadius="xxl"
                        gap="3"
                        fitWidth>
                        <Text color="heading-color">{environmentListOptions[0].label}</Text>
                    </Box>
                )}

                {environmentListOptions.length > 1 && (
                    <Field
                        component={MultiSelectField}
                        idForm={FORM_ID}
                        name="environments"
                        options={environmentListOptions}
                        initialOptions={props?.initialValues?.environments}
                        labelNoMarginTop
                        withSelectedLabel={i18nUtils.get("administration.users.invite.groups.placeholder")}
                        withSelectedLabelNoMoreOptions={i18nUtils.get(
                            "administration.users.invite.groups.placeholder2",
                        )}
                    />
                )}
            </Box>

            {environmentListOptions.length === 0 && (
                <Disclaimer
                    styled="error"
                    labelKey="administration.users.invite.noMore.companies.for.user"
                    image="images/icons/error.svg"
                    borderRadius="bottom-default"
                />
            )}
        </Box>
    );

    const { isInvitingNewUser, existentUserInfo, selectedDocument, invitationData } = props;

    if (!selectedDocument) {
        return <Redirect to="/administration/users/invite" />;
    }

    const isSecundaryInvi = invitationData ? isSecundaryInvitation(invitationData?.customParameters) : undefined;

    const invitationUsername = invitationData ? getInvitationUsername(invitationData?.customParameters) : undefined;

    const inputFieldsViewMode = isInvitingNewUser ? "edit" : "view";

    return (
        <>
            <Notification scopeToShow="administrationUserInvite" />
            <Head title="administration.users.invite.title" onBack={handleBack} />
            <MainContainer className="main-container" showLoader={false}>
                <Form>
                    <Row className="mb-8 full-height">
                        <Col xs={6}>
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className="py-6 px-9"
                                fullHeight>
                                <Box display="flex" className="mb-5">
                                    <Text
                                        component="h2"
                                        className="m-0"
                                        size="5"
                                        color="primary"
                                        bold
                                        labelKey="administration.users.invite.personal.data.subtitle"
                                    />
                                </Box>
                                <Box display="flex" className="mb-auto pb-9" column>
                                    {isInvitingNewUser && !isSecundaryInvi ? (
                                        <Field
                                            mode={inputFieldsViewMode}
                                            component={TextField}
                                            idForm={FORM_ID}
                                            initValue={invitationData ? `${invitationData.firstName}` : undefined}
                                            name="firstName"
                                            type="text"
                                            pattern="^[a-zA-Z0-9 ]*$"
                                        />
                                    ) : (
                                        <>
                                            <Box display="flex" alignX="between" className="mb-4" fullWidth>
                                                <Text
                                                    component="label"
                                                    labelKey="administration.permissions.user"
                                                    color="heading-color"
                                                    bold
                                                    className="m-0"
                                                    addColon
                                                />

                                                <Text color="heading-color">
                                                    {isSecundaryInvi ? invitationUsername : existentUserInfo?.userName}
                                                </Text>
                                            </Box>
                                            <Box display="flex" alignX="between" className="mb-4">
                                                <Text
                                                    component="label"
                                                    labelKey="administration.users.invite.firstName.label"
                                                    color="heading-color"
                                                    bold
                                                    className="m-0"
                                                    addColon
                                                />

                                                <Text color="heading-color">
                                                    {isSecundaryInvi
                                                        ? invitationData?.firstName
                                                        : existentUserInfo?.firstName}
                                                </Text>
                                            </Box>
                                        </>
                                    )}
                                    {isInvitingNewUser && !isSecundaryInvi ? (
                                        <Field
                                            mode={inputFieldsViewMode}
                                            component={TextField}
                                            idForm={FORM_ID}
                                            name="lastName"
                                            pattern="^[a-zA-Z0-9 ]*$"
                                            type="text"
                                            initValue={invitationData ? `${invitationData.lastName}` : undefined}
                                        />
                                    ) : (
                                        <Box display="flex" alignX="between" className="mb-4">
                                            <Text
                                                component="label"
                                                labelKey="administration.users.invite.lastName.label"
                                                color="heading-color"
                                                bold
                                                className="m-0"
                                                addColon
                                            />

                                            <Text color="heading-color">
                                                {isSecundaryInvi
                                                    ? invitationData?.lastName
                                                    : existentUserInfo?.lastName}
                                            </Text>
                                        </Box>
                                    )}
                                    {isInvitingNewUser && !isSecundaryInvi ? (
                                        <Field
                                            mode={inputFieldsViewMode}
                                            component={TextField}
                                            idForm={FORM_ID}
                                            name="email"
                                            type="text"
                                            pattern="^[a-zA-Z0-9ñ@+._-]*$"
                                            initValue={invitationData ? `${invitationData.email}` : undefined}
                                        />
                                    ) : (
                                        <Box display="flex" alignX="between" className="mb-4">
                                            <Text
                                                component="label"
                                                labelKey="administration.users.invite.email.label"
                                                color="heading-color"
                                                bold
                                                className="m-0"
                                                addColon
                                            />

                                            <Text color="heading-color">
                                                {isSecundaryInvi ? invitationData?.email : existentUserInfo?.email}
                                            </Text>
                                        </Box>
                                    )}
                                    {isInvitingNewUser && !isSecundaryInvi ? (
                                        <Field
                                            component={PhoneInput}
                                            idForm={FORM_ID}
                                            idField={`${FORM_ID}.mobilePhone`}
                                            name="mobilePhone"
                                            className="remove-flex"
                                            preferredCountries={preferredCountries}
                                            areaCodes={areaCodes}
                                            enableAreaCodes={preferredCountries}
                                            openUp
                                            mobilePhone={invitationData ? `${invitationData.mobileNumber}` : undefined}
                                            country="pa"
                                        />
                                    ) : (
                                        <Box display="flex" alignX="between">
                                            <Text
                                                component="label"
                                                labelKey="administration.users.invite.mobilePhone.label"
                                                color="heading-color"
                                                bold
                                                className="m-0"
                                                addColon
                                            />

                                            <Text color="heading-color">
                                                {isSecundaryInvi
                                                    ? invitationData?.mobileNumber
                                                    : existentUserInfo?.mobilePhone}{" "}
                                            </Text>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Col>
                        <Col xs={6}>
                            <Box display="flex" column gap="7" fullHeight>
                                {permissionsConfiguration()}
                                {inviteToCompany()}
                            </Box>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={3} xsOffset={3}>
                            <Button bsStyle="outline" label="global.cancel" onClick={() => handleCancel()} block />
                        </Col>
                        <Col xs={3}>
                            <Button bsStyle="primary" label="global.continue" loading={false} type="submit" block />
                        </Col>
                    </Row>
                </Form>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    environmentList: selectors.getEnvironmentList(state),
    encryptedDocument: selectors.getEncryptedDocument(state),
    selectedDocument: selectors.getSelectedDocument(state),
    isInvitingNewUser: selectors.isInvitingNewUser(state),
    existentUserInfo: selectors.getExistentUserInfo(state),
    roleList: selectors.getRoleList(state),

    invitationData: selectors.getInvitationData(state),
});

UserInviteStep2.propTypes = {
    dispatch: func.isRequired,
    isInvitingNewUser: bool.isRequired,
    environmentList: arrayOf(shape({ idEnvironmentAsString: string, name: string })).isRequired,
    existentUserInfo: shape({}).isRequired,
    roleList: arrayOf(shape({ id: string, label: string })).isRequired,
    selectedDocument: shape({ country: string, document: string, type: string }).isRequired,
    invitationData: shape({
        firstName: string,
        lastName: string,
        email: string,
        mobileNumber: string,
    }).isRequired,
    initialValues: shape({
        firstName: string,
        lastName: string,
        mobilePhone: string,
        email: string,
        role: string,
        environments: arrayOf(shape({})),
    }).isRequired,
};

UserInviteStep2.defaultProps = {};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { existentUserInfo, environmentList, invitationData } = props;
            const customerIds =
                invitationData &&
                JSON.parse(invitationData?.customParameters)?.customers?.map((customer) => customer?.customerId);
            const selectedEnvs = environmentList
                ?.filter((item) => item?.clients?.some((client) => customerIds?.includes(client.idClient)))
                ?.map((env) => ({ ...env, value: env.idEnvironment, label: env.name }));

            return {
                firstName: existentUserInfo ? existentUserInfo.firstName : "",
                lastName: existentUserInfo ? existentUserInfo.lastName : "",
                mobilePhone: existentUserInfo ? existentUserInfo.mobilePhone : "",
                email: existentUserInfo ? existentUserInfo.email : "",
                role: invitationData?.accessType ? invitationData.accessType : "transactions",
                environments:
                    selectedEnvs || (environmentList && environmentList.length === 1 ? [environmentList[0]] : ""),
            };
        },

        validationSchema: (props) => {
            const { isInvitingNewUser, invitationData } = props;

            const isSecundaryInvi = invitationData
                ? isSecundaryInvitation(invitationData?.customParameters)
                : undefined;

            return Yup.object().shape({
                firstName:
                    isInvitingNewUser && !isSecundaryInvi
                        ? Yup.string().required(i18nUtils.get("administration.users.firstNameEmpty"))
                        : Yup.string().notRequired(),
                lastName:
                    isInvitingNewUser && !isSecundaryInvi
                        ? Yup.string().required(i18nUtils.get("administration.users.lastNameEmpty"))
                        : Yup.string().notRequired(),
                email:
                    isInvitingNewUser && !isSecundaryInvi
                        ? Yup.string()
                              .email(i18nUtils.get("administration.users.emailIncorrectFormat"))
                              .required(i18nUtils.get("administration.users.emailEmpty"))
                        : Yup.string().notRequired(),
                mobilePhone:
                    isInvitingNewUser && !isSecundaryInvi
                        ? Yup.string().required(i18nUtils.get("administration.users.emailMobilePhone"))
                        : Yup.string().notRequired(),
                role: Yup.string().required(i18nUtils.get("administration.users.emptyRole")),
                environments: Yup.array()
                    .required(i18nUtils.get("administration.users.mustSelectGroup"))
                    .min(1, i18nUtils.get("administration.users.mustSelectGroup")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                selectedDocument,
                encryptedDocument,
                isInvitingNewUser,
                existentUserInfo,
                invitationData,
            } = formikBag.props;

            const isSecundaryInvi = invitationData
                ? isSecundaryInvitation(invitationData?.customParameters)
                : undefined;

            const { environments } = data;
            const listEnvironments = [];
            const environmentNames = [];

            if (environments) {
                environments.forEach((el) => {
                    listEnvironments.push(el.idEnvironment);
                    environmentNames.push(el.name);
                });
            }

            const getFieldValue = (field, defaultValue) => {
                if (isInvitingNewUser && !isSecundaryInvi) {
                    /* eslint-disable no-nested-ternary */
                    return existentUserInfo ? existentUserInfo[field] : data ? data[field] : defaultValue;
                }
                if (isSecundaryInvi) {
                    return invitationData ? invitationData[field] : defaultValue;
                }
                return data[field] || defaultValue;
            };

            const firstName = getFieldValue("firstName", "");
            const lastName = getFieldValue("lastName", "");
            const email = getFieldValue("email", "");
            let mobilePhone = isInvitingNewUser
                ? data.mobilePhone.areaCode + data.mobilePhone.value
                : existentUserInfo.mobilePhone;
            let prefix = isInvitingNewUser ? data.mobilePhone.prefix : "";
            if (isSecundaryInvi) {
                const numberDivided = invitationData.mobileNumber.split("-");
                mobilePhone = numberDivided[1];
                prefix = `${numberDivided[0]}-`;
            }

            const params = {
                ...data,
                environments: listEnvironments,
                firstName,
                lastName,
                email,
                mobilePhone,
                prefix,
                document: encryptedDocument,
                docCountry: selectedDocument.documentCountry,
                docType: selectedDocument.documentType,
                docNumber: invitationData
                    ? invitationData.documentNumber
                    : utilIdentification.generateIdentificationToSend(selectedDocument),
                signatureLevel: "N",
                environmentNames,
                idToEdit: invitationData ? invitationData.id : null,
            };

            if (isInvitingNewUser) {
                dispatch(actions.userSetUserInfo({ ...data, newUser: isInvitingNewUser }));
            }

            dispatch(actions.userInvitePreview(params, formikBag));
        },
    }),
)(UserInviteStep2);
